import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, ListItemButton, Grid } from '@mui/material';
// hooks
// import useResponsive from '../hooks/useResponsive';
// components
import Logo from './components/logo';
// sections
import { LoginForm } from './components/login';
import imgPortail from './assets/illustrations/illustration_login.png'
import itrade from './assets/itrade.png'

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

function App(props) {
  // const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Grid container spacing={2} style={{marginTop: "50px"}}>
        <Grid item xs={5} style={{textAlign: "center"}}>
          <img src={itrade} style={{height: '100px'}} alt="login" />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            <b>Portail Excel-MySQL</b>
          </Typography>
          <img src={imgPortail} alt="login" />
        </Grid>
        <Grid item xs={4} style={{marginTop: "100px"}}>
          <LoginForm />
        </Grid>
      </Grid>  
    </>
  );
}

export default App;