import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export default class DataService {

  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
      headers: {
        'content-type': 'application/json',
        'x-rapidapi-host': 'example.com',
        'x-rapidapi-key': process.env.RAPIDAPI_KEY
      }
    })
  }

  post = (url, data, config) => {
    return this.client.post(url, data, config)
  }

  get = (url, config) => {
    return this.client.get(url, config)
  }

  put = (url, config) => {
    return this.client.put(url, config)
  }

  delete = (url, config) => {
    return this.client.delete(url, config)
  }
}
