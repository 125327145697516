import { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert, AlertTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ant design 
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload} from 'antd';
// components
import Iconify from '../iconify';
import ConvertService from '../../services/convertService';

const { Dragger } = Upload;

// ----------------------------------------------------------------------

const propsFile = {
  name: 'file',
  multiple: true,
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};

function LoginForm(props) {


  const [table, setTable] = useState(null);
  const [token, setToken] = useState(null);
  const [sheet, setSheet] = useState(null);
  const [isLoad, setIsload] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChangeFile = (file) => {
    console.log(file);
    // const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    // if (status === 'done') {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  }

  const [fileList, setFileList] = useState([]);

  const params = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        setFileList([...fileList, file]);
        return false;
      },
      fileList,
  };

  const loadFile = () => {
    setIsload(true)
    console.log("file ", fileList[0])
    console.log("table ", table)
    console.log("sheet ", sheet)
    const formData = new FormData();
    formData.append('file', fileList[0]);
    formData.append('name', table);
    formData.append('sheet', sheet);
    formData.append('token', token);
    if(token === "69b46db4-013c-4533-bec2-253d9349e561"){
      new ConvertService().uploadFile(formData).then(res => {
        setIsload(false)
        message.success(`${fileList[0].name} file uploaded successfully.`);
      }).catch(e =>{
        setIsload(false)
        message.error(`${fileList[0].name} file upload failed.`);
      })
    }

    
  }
  
  return (
    <>
      {showAlert && 
        <Alert severity="error" style={{marginBottom: "20px"}}>
          <AlertTitle>{errorType}</AlertTitle>
          {errorMessage}
        </Alert>
      }
      <Stack spacing={3}>
        <Dragger  {...params}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
          </p>
        </Dragger>
        <TextField onChange={(e)=>setTable(e.target.value)} autoComplete='false' label="Nom de la table" name="name" />
        <TextField onChange={(e)=>setSheet(e.target.value)} type='number' label="feuille" name="feuille" />
        <TextField onChange={(e)=>setToken(e.target.value)} autoComplete='false' label="Token" name="token" />

      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>

      <LoadingButton loading={isLoad} disabled = {token != "69b46db4-013c-4533-bec2-253d9349e561"} onClick={loadFile} fullWidth size="large" type="submit" variant="contained">
        Charger
      </LoadingButton>
    </>
  );
}

export default LoginForm;