import DataService from "./dataService";

export default class ConvertService extends DataService {


  uploadFile = (data) => {
    return this.post(`/api/convert`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

}
